/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PaginatedTable from "../components/PaginatedTable";
import { getClaimRooms } from "../state/claims";
import { analyzeClaim, refetchClaim } from "../api";
import { getModels } from "../state/model";

function ClaimPage() {
    const { claimId } = useParams();
    const { byId, allIds } = useSelector((state) => state.claims.claimRooms);
    const { isLoading: spinnerActive } = useSelector((state) => state.claims);
    const claims = useSelector((state) => state.claims.allClaims.byId);
    const selectedClaim = claims[claimId];
    
    const [isLoading, setLoading] = useState(false);

    const { byId: modelById, allIds: allModelIds } = useSelector((state) => state.model.allModels);
    const [selectedModel, setSelectedModel] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();

    const getData = (data) => (rowData) => rowData[data];
    const columnNames = ["Name", "Total ARV", "Total RCV"];
    const columns = ["name", getData("total_arv"), getData("total_rcv")];

    useEffect(() => {
        dispatch(getClaimRooms(claimId));
        dispatch(getModels())
    }, []);

    const handleViewLineItems = (rowData) => {
        history.push(`/claims/${claimId}/rooms/${rowData.id}/line-items`);
    };

    const onAnalyze = async () => {
        setLoading(true);
        try {
            await analyzeClaim(selectedClaim.firebase_id, selectedModel);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
            dispatch(getClaimRooms(claimId));
        }
    };

    const onRefetch = async () => {
        setLoading(true);
        try {
            await refetchClaim(claimId);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
            dispatch(getClaimRooms(claimId));
        }
    };

    return (
        <div className="claim-page">
            <div className="claim-header-section d-flex justify-content-between align-items-center">
                <div className="title">
                    <h1 className="h2 mb-0">Claim Details</h1>
                </div>
                <div className="action-buttons">
                    <button
                        type="button"
                        className="btn btn-primary mr-3"
                        onClick={onRefetch}
                        disabled={isLoading}
                    >
                        Refetch OCR
                    </button>

                    <form className="form-inline mr-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">Model Version</div>
                            </div>
                            <select className="form-control" onChange={(e) => setSelectedModel(e.target.value)}>
                                {allModelIds.map(modelId => (
                                    <option 
                                        value={modelById[modelId].version} 
                                        key={modelId}
                                        selected={modelById[modelId].status === 'ACTIVE'}
                                    >
                                        {modelById[modelId].version}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </form>

                    <button
                        type="button"
                        className="btn btn-primary mr-3"
                        onClick={onAnalyze}
                        disabled={isLoading}
                    >
                        Analyze
                    </button>
                </div>
            </div>

            <div className="claim-body-section mt-5">
                <div className="">
                    {spinnerActive ? (
                        <div className="mt-3 d-flex justify-content-center">
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <PaginatedTable
                            columnNames={columnNames}
                            columns={columns}
                            data={byId}
                            rows={allIds}
                            count={allIds.length}
                            onRowClick={handleViewLineItems}
                            pageSize={20}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default ClaimPage;
